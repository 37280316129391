'use client'
import { cleanPath, handleKeyRandom } from "@/utils/functions"
import Link from "next/link"
import { CardHome } from "."
import { TWCarousel } from "../carousel"
import { useContext } from "react"
import { LoadingContext } from "@/context/tools/loading"

export const SectionHome = ({ ...props }) => {
  const {
    image, bottom, domain, text = ``, hostname, widthPerc = 33
  } = props
  const { modules } = useContext(LoadingContext)
  const { mobile, host } = hostname
  return (
    <TWCarousel {...props} {...{
      text, mobile, posslot: 200, height: 500
    }}>
      {image?.map((x, i) => (
        <Link
          onClick={() => modules.handleLinkLoading(true)}
          draggable="false"
          href={x.url.includes(`https`) ? cleanPath(x.url) : host + cleanPath(x.url) }
          passHref
          scroll={true}
          className="sections-link"
          style={{ "--wperc": `${widthPerc}%` }}
          key={handleKeyRandom(`section-img-${x.h1}`, i)}
        >
          <CardHome image={x} text={x.h1}
            {...{
              domain,
              hostname,
              bottom
            }} />
        </Link>
      ))}
    </TWCarousel>
  )
}

