import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"
import { constants } from "@/utils/global"
import { constructBannerOption } from "."

export const getBannersHome = async props => {
  const { events, platformId, mobile } = props
  const encrypt = await constructBannerOption(events)
  const sendData = {
    url: `${constants.URL.BANNER_HOME}${platformId}/${mobile ? `xl` : `xs`}`,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const result = await apiCall(encriptedData)
  return result.message
}
export const getSectionsHome = async props => {
  const { platformId } = props
  const sendData = {
    url: `${constants.URL.SECTIONS_HOME}${platformId}`,
    encrypt: ``,
    type: `GET`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const result = await apiCall(encriptedData)
  return result.message
}
