'use client'
import { ToolsContext } from "@/context/tools/tools"
import { handleText } from "@/utils/functions/home"
import IconsSVG from "@/utils/icons/IconsSVG"
import parse from "html-react-parser"
import { useContext, useMemo } from "react"
import { Htmlspan } from "../home/subcomponents/footer/home"

export const InfoHome = props => {
  const { styles } = props
  const { tools: { texts: { home_page }, colors }} = useContext(ToolsContext)
  const texts = useMemo(() => home_page?.info_home, [home_page])
  return (
    <div id="info-home" style={{ backgroundColor: colors.infoHomeBack ?? `#9ea1a755`, minHeight: `250px` }} className={`px-4 py-10 ${styles?.cardBorder || `card-rounded`} grid grid-cols-1 1200:grid-cols-3 mt-10`}>
      {texts && texts?.map((x, i) => (
        <div key={`info-home-${i}`} className="flex h-full m-auto px-4 flex-col items-center" >
          <IconsSVG name="home-icon" path={x.icon} size={30} fillcolor={colors.color}/>
          <p className="text-center font-bold uppercase text-base tablet:text-lg mb-4 mt-1" style={{ color: colors.infoHomeText }} >
            {parse(x.title)}
          </p>
          <Htmlspan {...{ colors }}>
            <p className="text-center mb-3" style={{ color: colors.infoHomeText }}>
              {parse(handleText(x.text, x.url))}
            </p>
          </Htmlspan>
        </div>
      )) }
    </div>
  )
}
