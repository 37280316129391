'use client'
import { ToolsContext } from "@/context/tools/tools"
import { getImageUrl } from "@/utils/functions/images/imagesHelper"
import parse from "html-react-parser"
import { useContext } from "react"

export const CardHome = ({
  image, bottom, text, domain, hostname, ...props
}) => {
  const { business, styles } = hostname
  const { tools: { colors }} = useContext(ToolsContext)
  const urlThumb = getImageUrl(image?.image, business?.thumb, domain)
  return <div draggable="false" style={{ backgroundImage: `url(${urlThumb})` }}
    className={`${styles?.cardBorder || `card-rounded`} bg-no-repeat bg-cover p-1 bg-center m-1 flex items-end w-[250px] tablet:w-full h-[200px] 1200:h-[250px] 1680:h-[300px] 2000:h-[400]`}
    loading="lazy"
    fetchPriority="high"
  >
    <span style={{ color: colors.color }}
      className={`${styles?.labelBorder || `label-rounded`} relative left-2 bg-white text-sm font-bold p-1 line-clamp-1 h-fit bottom-2 z-50 pl-2 pr-2 max-w-72 w-auto`}
    >
      {parse(text)}
    </span>
  </div>
}
