export const constructBannerOption = props => {
  return {
    black: props && props[`black-friday`],
    home: true,
    event: props && props[`banner-home`],
  }
}

export const handleText = (a, b) => {
  let temp
  if (b !== ``) {
    if (b?.length > 0) {
      temp = a
      b.forEach((item, idx) => {
        temp = temp.
          replace(`{alt${idx}}`, item.alt).
          replace(`{title${idx}}`, item.title).
          replace(`{href${idx}}`, item.href).
          replace(`{text${idx}}`, item.text)
      })
    } else {
      temp = a.replace(`{alt}`, b.alt).replace(`{title}`, b.title).
        replace(`{href}`, b.href).
        replace(`{text}`, b.text)
    }
  } else {
    temp = a
  }
  return temp
}

export const handleWidth = (a, b, c) => {
  const diff = !c ? 255 : 0
  const width = a ? b ? 725 - diff : 625 - diff : 300
  return width
}
export const handleHeight = (a, b, c) => {
  const diff = c ? 0 : 0
  const height = a ? b ? 300 + diff : 250 + diff : 200
  return height
}
