'use client'
import { ToolsContext } from "@/context/tools/tools"
import parse from "html-react-parser"
import { useContext, useMemo } from "react"

export const TitleHome = () => {
  const { tools: { texts }} = useContext(ToolsContext)
  const seo_fields = useMemo(() => texts?.home_page?.seo_fields, [texts])
  return !!seo_fields && (
    <div className="mb-5 mt-2 tablet:mt-0 mx-auto " >
      <h1 className="text-center">
        {parse(seo_fields.h1 ?? ``)}
      </h1>
      <p className="text-center text-base">
        {parse(seo_fields.h2 ?? ``)}
      </p>
    </div>
  )
}
