'use client'
import { LoadingContext } from '@/context/tools/loading'
import { useEffectOnce } from '@/hooks/utils/useEffectOnce'
import { useContext } from 'react'

export const Loading = () => {
  const { modules } = useContext(LoadingContext)
  useEffectOnce(() => {
    modules.handleLinkLoading(false)
  })
}
